import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { UserRole } from "../../../services/ApiClient";

import { NavCard } from "../../common/navCard/NavCard";
import { NavCardRow } from "../../common/navCard/NavCardRow";
import { PickPackStats } from "../../pages/pickPageStats/PickPackStats";

//Icons from material icons https://fonts.google.com/icons in rouded 48px, filled, weight 700.
//Re-colorured with CSS.
//
import { ReactComponent as PlaceStoreOrderIcon } from "../../../assets/add_shopping_cart_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as VanIcon } from "../../../assets/airport_shuttle_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as PackIcon } from "../../../assets/box_FILL1_wght700_GRAD200_opsz48.svg";
import {
  ReactComponent as AvailabilityIcon,
  ReactComponent as ReviewIcon,
} from "../../../assets/event_available_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as CutIcon } from "../../../assets/local_florist_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as ManageUsersIcon } from "../../../assets/manage_accounts_FILL0_wght700_GRAD200_opsz48.svg";
import { ReactComponent as UnprocessedIcon } from "../../../assets/pending_actions_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as PotsIcon } from "../../../assets/potted_plant_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/settings_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as PickIcon } from "../../../assets/shelves_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as MaterialsIcon } from "../../../assets/shopping_basket_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as StoreIcon } from "../../../assets/store_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as UploadIcon } from "../../../assets/upload_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as TrendingDown } from "../../../assets/trending_down_48dp_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as Stocktake } from "../../../assets/inventory_48dp_5F6368_FILL0_wght700_GRAD200_opsz48.svg";

export const Home = () => {
  return (
    <Container>
      <Row xs={1} sm={1} md={1} lg={1} className="g-4">
        <NavCardRow
          title={"Place order"}
          requiredRoles={[
            UserRole.Merchandiser,
            UserRole.InStoreStaff,
            UserRole.RetailManager,
            UserRole.Manager,
            UserRole.RegionalManager,
          ]}
        >
          <NavCard
            title={"Place store order"}
            icon={PlaceStoreOrderIcon}
            navigateTo={"/stores"}
            requiredRoles={[
              UserRole.Merchandiser,
              UserRole.InStoreStaff,
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
          <NavCard
            title={"Upload pre-orders"}
            icon={UploadIcon}
            navigateTo={"/orders/upload"}
            requiredRoles={[UserRole.Manager]}
          />
        </NavCardRow>
        <NavCardRow
          title={"Existing orders"}
          requiredRoles={[
            UserRole.RetailManager,
            UserRole.Manager,
            UserRole.RegionalManager,
          ]}
        >
          {/*<NavCard
              title={"Total ordered"}
              icon={TotalOrderedIcon}
              navigateTo={"/orders"}
            />
            */}
          <NavCard
            title={"View cut orders"}
            icon={CutIcon}
            navigateTo={"/orders/cut"}
            requiredRoles={[
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
          <NavCard
            title={"View pots orders"}
            icon={PotsIcon}
            navigateTo={"/orders/pots"}
            requiredRoles={[
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
          <NavCard
            title={"View materials orders"}
            icon={MaterialsIcon}
            navigateTo={"/orders/materials"}
            requiredRoles={[
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
        </NavCardRow>
        <NavCardRow
          title={"Unprocessed orders"}
          requiredRoles={[
            UserRole.RetailManager,
            UserRole.Manager,
            UserRole.RegionalManager,
          ]}
        >
          <NavCard
            title={"Unprocessed cut"}
            icon={UnprocessedIcon}
            navigateTo={"/orders/cut/unprocessed"}
            requiredRoles={[
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
          <NavCard
            title={"Unprocessed pots"}
            icon={UnprocessedIcon}
            navigateTo={"/orders/pots/unprocessed"}
            requiredRoles={[
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
          <NavCard
            title={"Unprocessed materials"}
            icon={UnprocessedIcon}
            navigateTo={"/orders/materials/unprocessed"}
            requiredRoles={[
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
        </NavCardRow>
        <NavCardRow
          title={"Credits, markdowns & throwouts"}
          requiredRoles={[
            UserRole.Manager,
            UserRole.Merchandiser,
            UserRole.RetailManager,
            UserRole.RegionalManager,
          ]}
        >
          <NavCard
            title={"Review your credit requests"}
            icon={ReviewIcon}
            navigateTo={"/merchandisecreditrequests"}
            requiredRoles={[UserRole.Merchandiser]}
          />
          <NavCard
            title={"Review credit requests"}
            icon={ReviewIcon}
            navigateTo={"/reviewCreditRequests"}
            requiredRoles={[
              UserRole.Manager,
              UserRole.RegionalManager,
              UserRole.RetailManager,
            ]}
          />
          <NavCard
            title={"Markdowns & throwouts"}
            icon={TrendingDown}
            navigateTo={"/markdowns"}
            requiredRoles={[
              UserRole.Manager,
              UserRole.Merchandiser,
              UserRole.RetailManager,
              UserRole.RegionalManager,
            ]}
          />
          <NavCard
            title={"Review markdowns & throwouts"}
            icon={TrendingDown}
            navigateTo={"/reviewmarkdowns"}
            requiredRoles={[
              UserRole.Manager,
              UserRole.RegionalManager,
              UserRole.RetailManager,
            ]}
          />
          <NavCard
            title={"Stocktake"}
            icon={Stocktake}
            navigateTo={"/stocktake"}
            requiredRoles={[
              UserRole.Manager,
              UserRole.Merchandiser,
              UserRole.RetailManager,
              UserRole.RegionalManager,
            ]}
          />
          <NavCard
            title={"Review stocktake"}
            icon={Stocktake}
            navigateTo={"/reviewstocktake"}
            requiredRoles={[
              UserRole.Manager,
              UserRole.RegionalManager,
              UserRole.RetailManager,
            ]}
          />
        </NavCardRow>
        <NavCardRow title={"Budgets"} requiredRoles={[UserRole.Manager]}>
          <NavCard
            title={"Upload cut budget overrides"}
            icon={UploadIcon}
            navigateTo={"/budgets/upload/cut"}
            requiredRoles={[UserRole.Manager]}
          />
          <NavCard
            title={"Upload pots budgets"}
            icon={UploadIcon}
            navigateTo={"/budgets/upload/pots"}
            requiredRoles={[UserRole.Manager]}
          />
          <NavCard
            title={"Upload materials budgets"}
            icon={UploadIcon}
            navigateTo={"/budgets/upload/materials"}
            requiredRoles={[UserRole.Manager]}
          />
        </NavCardRow>
        <NavCardRow title={"Settings"} requiredRoles={[UserRole.Manager]}>
          <NavCard
            title={"Portal settings"}
            icon={SettingsIcon}
            navigateTo={"/settings"}
            requiredRoles={[UserRole.Manager]}
          />
          <NavCard
            title={"Store settings"}
            icon={StoreIcon}
            navigateTo={"/storesettings"}
            requiredRoles={[UserRole.Manager]}
          />
          <NavCard
            title={"Product availability"}
            icon={AvailabilityIcon}
            navigateTo={"/inventory/availability"}
            requiredRoles={[UserRole.Manager]}
          />
          <NavCard
            title={"Users"}
            icon={ManageUsersIcon}
            navigateTo={"/settings/users"}
            requiredRoles={[UserRole.Manager]}
          />
        </NavCardRow>
        <NavCardRow
          title={"Packhouse"}
          requiredRoles={[UserRole.Packhouse, UserRole.Manager]}
        >
          <NavCard
            title={"Pick order"}
            icon={PickIcon}
            navigateTo={"/pick"}
            requiredRoles={[UserRole.Packhouse, UserRole.Manager]}
          />
          <NavCard
            title={"Pack order"}
            icon={PackIcon}
            navigateTo={"/pack"}
            requiredRoles={[UserRole.Packhouse, UserRole.Manager]}
          />
          <PickPackStats />
        </NavCardRow>
        <NavCardRow
          title={"Transfers"}
          requiredRoles={[
            UserRole.Manager,
            UserRole.RetailManager,
            UserRole.RegionalManager,
          ]}
        >
          <NavCard
            title={"Van transfers"}
            icon={VanIcon}
            navigateTo={"/vantransfers"}
            requiredRoles={[
              UserRole.Manager,
              UserRole.RetailManager,
              UserRole.RegionalManager,
            ]}
          />
        </NavCardRow>
      </Row>
    </Container>
  );
};
