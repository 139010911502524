/****************************************************************************************************
 * Imports
 ****************************************************************************************************/
import { useCallback, useEffect, useState, useMemo } from "react";
import {
  ApiException,
  MarkdownClient,
  MarkdownDTO,
} from "../../../services/ApiClient";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  ICellRendererParams,
  NewValueParams,
  ValueFormatterParams,
} from "ag-grid-community";
import useWindowDimensions from "../../../ultilities/UseWIndowDimensions";
import { NoCards } from "../../layouts/NoCards";
import { Card, Col } from "react-bootstrap";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { DateRenderer } from "../../common/dateRenderer/DateRenderer";
import { DeleteItemRenderer } from "../../common/deleteItemRenderer/DeleteItemRenderer";

/****************************************************************************************************
 * Interfaces
 ****************************************************************************************************/
interface IReviewMarkdownProps {
  pageTitle: string;
}
interface IModalDetails {
  title: string;
  message: string;
}

/****************************************************************************************************
 * Component
 ****************************************************************************************************/
export const ReviewMarkdowns = (props: IReviewMarkdownProps) => {
  //console.log("Rendering ReviewMarkdowns...");
  const { width } = useWindowDimensions();

  /************************************************************************************************
   * States
   ************************************************************************************************/
  const [isFetching, setIsFetching] = useState(true);
  const [modalDetails, setModalDetails] = useState<IModalDetails>({
    title: "",
    message: "",
  });

  //const [markdowns, setMarkdowns] = useImmer<MarkdownDTO[] | null>(null);
  const [markdowns, setMarkdowns] = useState<MarkdownDTO[] | null>(null);

  // Add to states section
  const [searchFilter, setSearchFilter] = useState<string>("");

  //console.log("Markdowns in Component", markdowns);

  /************************************************************************************************
   * Effects
   ************************************************************************************************/
  useEffect(() => {
    //console.log("Executing useEffect...");
    const getMarkdowns = async () => {
      try {
        const markdowns = await new MarkdownClient(
          process.env.REACT_APP_API_BASE
        ).getMarkdownsForReview(28);
        setMarkdowns(markdowns);
        //console.log("markdowns in useEffect", markdowns);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log("getMarkdowns error: " + error.message);
      } finally {
        setIsFetching(false);
      }
    };

    getMarkdowns().catch(console.error);
  }, [setMarkdowns]);

  /************************************************************************************************
   * Support Functions
   ************************************************************************************************/
  const deleteMarkdown = useCallback(
    async (markdown: MarkdownDTO) => {
      try {
        if (markdowns) {
          await new MarkdownClient(
            process.env.REACT_APP_API_BASE
          ).deleteMarkdown(markdown.markdownId);
          //console.log("Deleting markdown " + JSON.stringify(markdown.markdownId));
          const markdownsCopy = markdowns.filter(
            (item) => item.markdownId !== markdown.markdownId
          );
          setMarkdowns(markdownsCopy);

          // setMarkdowns((draft) => {
          //     return draft?.filter((item) => item.markdownId !== markdown.markdownId);
          // });
        }
      } catch (e: unknown) {
        const error = e as ApiException;
        setModalDetails({
          title: "Error",
          message: JSON.parse(error.response).errorMessages[0],
        });
      }
    },
    [markdowns]
  );

  const onMarkdownPriceChanged = useCallback(
    async (p: NewValueParams) => {
      //console.log("onMarkdownPriceChanged", p.data);
      try {
        if (markdowns) {
          await new MarkdownClient(
            process.env.REACT_APP_API_BASE
          ).updateMarkdown(p.data);
          const markdownsCopy = [...markdowns];
          const itemToUpdate = markdownsCopy.find(
            (item) => item.markdownId === p.data.markdownId
          );
          if (itemToUpdate) itemToUpdate.markdownPrice = Number(p.newValue);
          setMarkdowns(markdownsCopy);
        }
      } catch (e: unknown) {
        const error = e as ApiException;
        setModalDetails({
          title: "Error",
          message: JSON.parse(error.response).errorMessages[0],
        });
      }
    },
    [markdowns]
  );

  const onQtyChanged = useCallback(
    async (p: NewValueParams) => {
      //console.log("onQtyChanged", p.data);
      try {
        if (markdowns) {
          await new MarkdownClient(
            process.env.REACT_APP_API_BASE
          ).updateMarkdown(p.data);
          const markdownsCopy = [...markdowns];
          const itemToUpdate = markdownsCopy.find(
            (item) => item.markdownId === p.data.markdownId
          );
          if (itemToUpdate) itemToUpdate.quantity = Number(p.newValue);
          setMarkdowns(markdownsCopy);
        }
      } catch (e: unknown) {
        const error = e as ApiException;
        setModalDetails({
          title: "Error",
          message: JSON.parse(error.response).errorMessages[0],
        });
      }
    },
    [markdowns]
  );

  // Add this function in the support functions section
  const filteredMarkdowns = useMemo(() => {
    if (!markdowns || !searchFilter) return markdowns;

    return markdowns.filter((item) =>
      item.itemName.toLowerCase().includes(searchFilter.toLowerCase())
    );
  }, [markdowns, searchFilter]);

  /************************************************************************************************
   * Table Cell Formatters
   ************************************************************************************************/
  const currencyFormatter = (p: ValueFormatterParams) => "$" + p.value;

  /************************************************************************************************
   * Table Column Definitions
   ************************************************************************************************/
  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    lockPosition: true,
    resizable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  };

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Date",
        field: "markdownDate",
        cellDataType: "date",
        cellRenderer: DateRenderer,
        width: 120,
        filter: "agDateColumnFilter",
      },
      {
        headerName: "Store",
        field: "storeName",
        width: 100,
      },
      {
        headerName: "Item",
        field: "itemName",
        minWidth: 100,
      },
      {
        headerName: "Comment",
        field: "comment",
        flex: 2,
      },
      {
        headerName: "Original Price",
        cellHeaderStyle: { "text-align": "center" },
        field: "originalPrice",
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
      },
      
      {
        headerName: "Markdown Price",
        field: "markdownPrice",
        editable: true,
        valueFormatter: currencyFormatter,
        onCellValueChanged: onMarkdownPriceChanged,
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "Qty",
        field: "quantity",
        editable: true,
        type: "number",
        maxWidth: 90,
        minWidth: 90,
        onCellValueChanged: onQtyChanged,
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "",
        field: "markdownId",
        flex: 1,
        filter: false,
        cellRendererSelector: (params: ICellRendererParams<any>) => {
          const button = {
            component: DeleteItemRenderer,
          };
          return button;
        },
        cellRendererParams: {
          deleteItemCallback: deleteMarkdown,
        },
      },
    ],
    [markdowns, deleteMarkdown, onMarkdownPriceChanged, onQtyChanged]
  );

  //console.log("Markdowns before render", markdowns);

  /************************************************************************************************
   * Render
   ************************************************************************************************/
  return (
    <>
      <NoCards pageTitle={"Review Markdown / Throw-outs"}>
        {!isFetching && markdowns && markdowns.length > 0 ? (
          <>
            <Col lg={12}>
              <Card className="shadow-sm" border="light">
                {/*<div className="search-container">
                  <Form.Control
                    type="text"
                    placeholder="Search by product name..."
                    value={searchFilter}
                    onChange={(e) => setSearchFilter(e.target.value)}
                  />
                </div>
                  */}
                <div className="ag-theme-alpine ag-row-pointer available-products-container">
                  <AgGridReact
                    rowData={filteredMarkdowns}
                    //ref={props.gridRef}
                    className={`ag-theme-alpine`}
                    columnDefs={columnDefs}
                    domLayout="autoHeight"
                    singleClickEdit={true}
                    alwaysShowHorizontalScroll={false}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={50}
                  />
                </div>
              </Card>
            </Col>
          </>
        ) : null}

        {!isFetching && (markdowns === null || markdowns?.length === 0) ? (
          <Col lg={12}>
            <Card className="shadow-sm" border="light">
              <div className="no-items">No items available.</div>
            </Card>
          </Col>
        ) : null}

        {isFetching ? (
          <Col lg={12}>
            <CardPlaceholder />
          </Col>
        ) : null}
      </NoCards>
    </>
  );
};
